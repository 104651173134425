<template>
  <div class="grid">
    <div class="flex col-12 justify-content-center" v-if="loading" style="position: absolute;top: 50%;transform: translate(0, -50%);">
      <ProgressSpinner></ProgressSpinner>
    </div>
    <div class="col-12 lg:col-4" v-show="!loading">
      <Card>
        <template #title>Поиск заявки / займа</template>
        <template #content>
          <AbstractForm
            ref="form"
            :loading="formLoading"
            v-model="searchForm"
            :on-commit="searchApplication"
            :after-clear="() => {this.event = null}"
            commit-label="Найти"
            commit-icon="pi pi-search"
          ></AbstractForm>
        </template>
      </Card>
    </div>
    <div class="col-12 lg:col-8" v-if="event !== null && !loading">
      <Card>
        <template #title>Событие #{{ event.id }}</template>
        <template #content>
          <p><strong>UUID заявки: </strong>{{ event.applicationUuid }}</p>
          <p><strong>ЦБ УИД: </strong>{{ event.cbUuid }}</p>
          <br/>
          <p><strong>UUID заёмщика: </strong>{{ event.borrowerUuid }}</p>
          <p><strong>ФИО: </strong>{{ event.data.src.borrowerData.surname }} {{ event.data.src.borrowerData.name }} {{ event.data.src.borrowerData.patronymic }}</p>
          <p><strong>Паспорт: </strong>{{ event.data.src.borrowerData.passport.series }} {{ event.data.src.borrowerData.passport.number }}</p>
          <br/>
          <p><strong>Тип события: </strong>{{ $filters.translate($store, 'eventType', event.type) }}</p>
        </template>
        <template #footer v-if="this.actions !== null">
          <SplitButton label="Действия" icon="pi pi-bolt" :model="generateItems(event)"></SplitButton>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import AbstractForm from "@/components/AbstractForm.vue";
import FormModel from "@/model/FormModel";
import FunctionsService from "@/service/FunctionsService";
import ResponseService from "@/service/ResponseService";

export default {
  name: "EventSearchForm",
  components: {AbstractForm},
  props: {
    sendStopCheck: {
      type: Boolean,
      default: () => true
    },
    actions: {
      type: Function,
      default: () => null
    }
  },
  data() {
    return {
      loading: false,
      formLoading: false,
      searchForm: {
        applicationUuid: new FormModel('UUID заявки / займа')
      },
      event: null
    }
  },
  methods: {
    generateItems(event) {
      return this.actions(event);
    },
    searchApplication() {
      let uuid = this.searchForm.applicationUuid.value
      if (typeof uuid === 'string') {
        uuid = this.searchForm.applicationUuid.value.trim()
      }

      this.formLoading = true
      FunctionsService.getDeleteData(uuid, this.sendStopCheck).then((res) => {
        console.log(this.sendStopCheck);
        if (res.error !== null) {
          this.$toast.add({
            severity: 'warn',
            summary: 'Ошибка поиска заявки',
            detail: res.error,
            life: 5000
          })
        }
        this.event = res.event
      })
      .catch((err) => {
        ResponseService.handleErrorResponseWithForm(err, this.searchForm, this.$toast)
      })
      .finally(() => {
        this.formLoading = false
      })
    },
    setLoading(value) {
      this.loading = value
    },
    clear() {
      this.$refs.form.clear();
    }
  }
}
</script>